const API_ENDPOINT = 'https://new-read-submissions.emmascally2026.workers.dev'; // Replace with your Cloudflare Worker URL

export async function fetchCode() {
  try {
    const response = await fetch(API_ENDPOINT);
    if (response.ok) {
      const codeData = await response.json();
      return codeData;
    } else {
      throw new Error('Error fetching code:', response.statusText);
    }
  } catch (error) {
    throw new Error('An error occurred while fetching code');
  }
}

