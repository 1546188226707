




import React, { useEffect, useState } from 'react';
import { fetchCode } from './utils/api';

function App() {
  const [codeData, setCodeData] = useState({});

  useEffect(() => {
    fetchCode()
      .then(codeData => {
        setCodeData(codeData);
      })
      .catch(error => {
        console.error('Error fetching code:', error);
      });
  }, []);

  const fetchAndDisplayCode = async () => {
    try {
      setLoading(true);
      const fetchedCodeData = await fetchCode();
      setCodeData(fetchedCodeData);
    } catch (error) {
      console.error('Error fetching code:', error);
    } finally {
      setLoading(false);
    }
  };

  const [loading, setLoading] = useState(false);

  return (
    <div>
      <h1>Code Submissions</h1>
      <button
        onClick={fetchAndDisplayCode}
        disabled={loading}
        style={{
          backgroundColor: '#007bff',
          color: 'white',
          padding: '10px 20px',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          opacity: loading ? 0.7 : 1,
        }}
      >
        {loading ? 'Fetching...' : 'Fetch Code Submissions'}
      </button>
      <ul>
        {Object.keys(codeData).map((timepoint, index) => (
          <li key={index}>Time {timepoint}: {codeData[timepoint]}</li>
        ))}
      </ul>
    </div>
  );
}

export default App;


/*App.js
import React, { useState } from 'react';
import { submitCode } from './utils/api';

function App() {
  const [code, setCode] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (code.trim() === '') {
      return;
    }

    try {
      await submitCode(code);
      console.log('Code submitted successfully!');
    } catch (error) {
      console.error('Error submitting code:', error);
    }
  };

  return (
    <div>
      <h1>Code Submission</h1>
      <form onSubmit={handleSubmit}>
        <textarea
          value={code}
          onChange={(e) => setCode(e.target.value)}
          rows={10}
          cols={50}
        />
        <button type="submit">Submit Code</button>
      </form>
    </div>
  );
}

export default App;


/*function App() {
  return (
    <Router>
      <Switch>
        <Route path="/submissions/:id">
          <Submission />
        </Route>
        <Route path="/submissions">
          <Submissions />
        </Route>
        {Add other routes here}
      </Switch>
    </Router>
  );
}

export default App;*/
